var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-2 pr-2 h-max-content"},[_c('b-overlay',{staticClass:"mh-screen",attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('div',{staticClass:"d-flex justify-content-between button-line"},[_c('b-btn',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.openPridatSoubor()}}},[_vm._v(" Přidat soubor ")]),_c('div',{staticClass:"pr-3 mt-2 text-right"},[_vm._v(" Využití prostoru: "+_vm._s(_vm.fileSize(_vm.totalSize))),_c('br'),_vm._v(" z možných "+_vm._s(_vm.fileSize(_vm.allowedFileSize))+" ")])],1),[_c('b-table',{staticClass:"stickyTable",attrs:{"sticky-header":"calc(100vh - 14.5rem)","bordered":"","responsive":"","hover":"","no-border-collapse":"","items":_vm.filesInfo,"fields":_vm.headers,"sort-direction":"desc"},scopedSlots:_vm._u([{key:"cell(link)",fn:function(ref){
var item = ref.item;
return [_c('b-icon-clipboard-plus',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                title: 'Kopírovat odkaz ke stažení',
                delay: { show: 200, hide: 200 },
                variant: 'secondary'
              }),expression:"{\n                title: 'Kopírovat odkaz ke stažení',\n                delay: { show: 200, hide: 200 },\n                variant: 'secondary'\n              }",modifiers:{"hover":true}}],staticClass:"user-enabled-email cursor-pointer mr-2",on:{"click":function($event){return _vm.copyLink(item)}}}),_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.link))])]}},{key:"cell(originalFileName)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('b-icon-clipboard-plus',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                  title: 'Kopírovat název souboru ke stažení + poznámku',
                  delay: { show: 200, hide: 200 },
                  variant: 'secondary'
                }),expression:"{\n                  title: 'Kopírovat název souboru ke stažení + poznámku',\n                  delay: { show: 200, hide: 200 },\n                  variant: 'secondary'\n                }",modifiers:{"hover":true}}],staticClass:"user-enabled-email cursor-pointer mr-2",on:{"click":function($event){return _vm.copyFileNamePoznamka(item)}}}),_vm._v(" "+_vm._s(item.originalFileName)+" "),_c('span',{staticClass:"color-grey"},[_vm._v("("+_vm._s(_vm.fileSize(item.size))+")")]),(item.comment)?_c('div',{staticClass:"color-grey fs-12px"},[_vm._v("Poznámka: "+_vm._s(item.comment))]):_vm._e()],1)]}},{key:"cell(uploadedByUser)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.uploadedByUser)+", "+_vm._s(_vm.uploadedDate(item.uploadedDate))+" ")]}},{key:"cell(deleteButton)",fn:function(ref){
                var item = ref.item;
return [_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.confirmDeleteFile(item)}}},[_vm._v(" Smazat ")])]}}])})],(_vm.showPridatSouborModal)?_c('pridat-soubor-uschovna-form',{attrs:{"show":_vm.showPridatSouborModal,"totalSize":_vm.totalSize,"allowedFileSize":_vm.allowedFileSize},on:{"close-modal":function($event){_vm.showPridatSouborModal = false},"add-file":_vm.getMainData}}):_vm._e()],2),_c('b-modal',{attrs:{"id":"delete-file-confirmation-modal","centered":"","hide-footer":"","title":_vm.getFileName,"dialog-class":"main-font mnogotochie"}},[_c('p',{staticClass:"my-3 bold"},[_vm._v("Opravdu chcete tento soubor trvalé smazat?")]),_c('div',{staticClass:"d-flex justify-content-end pt-3"},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"primary"},on:{"click":_vm.closeDeleteFileModal}},[_vm._v("Zrušit")]),_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.deleteFile}},[_vm._v("Ano")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }