<template>
  <div :class="$attrs.class">
    <ValidationProvider
        :rules="{ required, mustBeInList: { list: OPList }}"
        :name="`'${placeholder}'`" mode="lazy" v-slot="{ classes, errors }"
    >
      <b-form-input
          :id="'orderNumber' + index"
          :list="'OPList' + index"
          :name="'orderNumber' + index"
          class="form-control form-control-sm"
          :placeholder="placeholder"
          v-model.trim="orderNumberLocal"
          :title="errors[0]"
          :state="getState(classes) && !validateWithDataFromList('orderNumberLocal', null, 'OPList', true)"
          @input="changeData"
      />
    </ValidationProvider>
    <b-datalist
        :id="'OPList' + index"
        :options="OPList"
        autocomplete="off"
    />
  </div>
</template>

<script>
import commonMixin from "@/mixins/common.mixin.ts";
import _ from "lodash";
import axios from "axios";

export default {
  mixins: [commonMixin],
  props: {
    orderNumber: { type: String, default: '' },
    index: { default: '' },
    contractId: { type: String, default: '' },
    placeholder: { type: String, default: 'Obchodní případ / zakázka' },
    searchUrl: { type: String, default: '' },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      OPList: [],
      OPListFull: [],
      orderNumberLocal: null,
      contractIdLocal: null,
    };
  },
  methods: {
    validateWithDataFromList(key, isInput, listName, isCanBeEmpty = false) {
      if (isCanBeEmpty && !this[key]) return false;
      const isEqual = this[listName || key].includes(_.get(this, key));
      return isInput && !isEqual ? false : !isEqual;
    },
    fillOPList(list) {
      this.OPList = [];
      list.map(info => {
        this.OPList.push(info.orderNumber);
      });
      this.OPListFull = list;
    },
    async searchOP() {
      try {
        if (this.orderNumberLocal?.length < 3 || this.getContractId()) {
          return;
        }

        const {data: {data}} = await axios.get(this.searchUrl, {params: {search: this.orderNumberLocal}});
        this.fillOPList(data?.info || []);
      } catch (e) {
        console.error(e);
      }
    },
    getContractId() {
      const contract = this.OPListFull.find(info => info.orderNumber === this.orderNumberLocal);
      return contract?.contractId;
    },
    async changeData() {
      await this.searchOP();
      this.contractIdLocal = this.getContractId();
      this.$emit('change-contract-id', this.contractIdLocal);
    },
  },
  async mounted() {
    this.contractIdLocal = this.contractId;

    if (this.orderNumber) {
      this.orderNumberLocal = this.orderNumber;
      this.OPList = [this.orderNumber];
      this.OPListFull = [{ orderNumber: this.orderNumberLocal, contractId: this.contractIdLocal }];
    }
  },
};
</script>
