<template>
  <b-modal
    v-model="showLocal"
    scrollable centered hide-footer no-close-on-backdrop
    dialog-class="pridatSoubor-form"
    @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Přidat soubor</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm" class="h-100">
      <div class="modalWindow-w100 h-100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit()" class="h-100">
            <div class="d-flex flex-column justify-content-between h-100">
              <div>
                <op-search-data-list
                    :searchUrl="'/cloud/searchObchodniPripady'"
                    :required="true"
                    class="w-100"
                    @change-contract-id="changeContractId"
                />

                <VueFileAgent
                    ref="vueFileAgent"
                    :theme="'list'"
                    :multiple="false"
                    :deletable="true"
                    :maxFiles="1"
                    :helpText="'Vyberte nebo přetáhněte soubor do tohoto pole'"
                    :linkable="true"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    v-model="files"
                    class="mt-4"
                />

                <ValidationProvider :rules="`max:${maxCommentLength}`" name="'Poznámka'" v-slot="{ classes, errors }">
                  <b-form-textarea
                      id="fileComment"
                      v-model="comment"
                      placeholder="Poznámka"
                      rows="2"
                      max-rows="4"
                      class="fs-14px mt-4"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>

                <div class="d-flex mb-3 mt-4 justify-content-center w-100">
                  <span class="bold text-nowrap pt-label pr-1">Smazat po</span>
                  <label class="req pr-3">*</label>
                  <b-form-datepicker
                      id="expiredDate"
                      v-model="expiredDate"
                      placeholder="Vyberte datum"
                      :start-weekday="1"
                      size="sm"
                      class="w-260px"
                      dropleft
                      locale="cs"
                  />
                </div>
              </div>

              <div class="d-flex justify-content-center w-100>">
                <b-button
                    @click="uploadFiles()"
                    type="submit"
                    class="mt-5 mb-2"
                    variant="primary"
                    :disabled="disableButton"
                >
                  Uložit data a zavřít
                </b-button>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import OpSearchDataList from "@/components/OpSearchDataList.vue";
import commonMixin from "@/mixins/common.mixin.ts";
import axios from "axios";
import _ from "lodash";
const TOKEN_KEY = 'jwt-token';

const maxCommentLength = 200;

export default {
  mixins: [commonMixin],
  components: {
    OpSearchDataList,
  },
  computed: {
    isLongComment() {
      return this.comment?.length > this.maxCommentLength;
    },
    disableButton() {
      return !this.filesForUpload.length || !this.expiredDate || this.isUploading || this.isLongComment || !this.contractId;
    },
  },

  props: {
    fileName: { type: String },
    show: { type: Boolean },
    totalSize: { type: Number },
    allowedFileSize: { type: Number },
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      expiredDate: null,
      files: [],
      filesForUpload: [],
      isUploading: false,
      urlApiUploadFiles: `${process.env.VUE_APP_BACKEND_URL}/cloud/uploadFile`,
      contractId: null,
      comment: '',
      maxCommentLength,
    }
  },
  methods: {
    filesSelected: function (fileRecordsNewlySelected) {
      this.filesForUpload = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
    },
    onBeforeDelete: async function (fileRecord) {
      let i = this.filesForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.filesForUpload.splice(i, 1);
        let k = this.files.indexOf(fileRecord);
        if (k !== -1) this.files.splice(k, 1);
      }
    },
    async getFilesSize() {
      try {
        const response = await axios.get('/cloud/getFilesSize', {});
        this.totalSize = response.data?.data?.totalSize || 0;
      } catch (e) {
        console.error(e);
      }
    },
    uploadFiles: async function () {
      try {
        if (!this.filesForUpload.length) {
          return;
        }

        await this.getFilesSize(); // needs to refresh token if it's expired
        const filesSize = this.filesForUpload.reduce((acc, file) => acc + file.file.size, 0);
        if (filesSize + this.totalSize > this.allowedFileSize) {
          this.$snotify['error'](`Soubory přesahují maximální povolenou velikost ${this.fileSize(this.allowedFileSize)}`);
          return;
        }

        this.isUploading = true;
        const uploadHeaders = this.getHeaders();
        _.set(this.filesForUpload, '[0].file.comment', this.comment);
        const formDataFunc = (fileRecord) => {
          const formData = new FormData();
          formData.append('file', fileRecord.file);
          formData.append('filename', fileRecord.name());
          formData.append('comment', fileRecord.file?.comment);
          return formData;
        };
        const resultsOfUpload = await this.$refs.vueFileAgent.upload(
            this.urlApiUploadFiles, uploadHeaders, this.filesForUpload, formDataFunc
        );
        resultsOfUpload.forEach(r => {
          const {data} = r;
          this.$snotify[data.status](data.data.message);
        });
        this.filesForUpload = [];
      } catch (e) {
        console.log('uploadFiles', e);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      } finally {
        this.isUploading = false;
        this.$emit('close-modal');
        this.$emit('add-file');
      }
    },
    getHeaders: function () {
      return {
        'Authorization': `Bearer ${ localStorage.getItem(TOKEN_KEY) }`,
        filename: this.fileName,
        expireddate: this.expiredDate,
        contractid: this.contractId,
      };
    },
    changeContractId(contractId) {
      this.contractId = contractId;
    },
  },
  async mounted() {
    this.isLoading = true;

    this.isLoading = false;
    this.showLocal = this.show;
  },
}
</script>

<style scoped>
.req {
  color: darkred;
  font-size: 17px
}
</style>