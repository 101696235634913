<template>
  <div class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="d-flex justify-content-between button-line">
        <b-btn
          @click="openPridatSoubor()"
          variant="outline-primary"
        >
          Přidat soubor
        </b-btn>
        <div class="pr-3 mt-2 text-right">
          Využití prostoru: {{ fileSize(totalSize) }}<br/>
          z možných {{ fileSize(allowedFileSize) }}
        </div>
      </div>

      <template>
        <b-table
          sticky-header="calc(100vh - 14.5rem)"
          bordered
          responsive
          hover
          no-border-collapse
          class="stickyTable"
          :items="filesInfo"
          :fields="headers"
          sort-direction="desc"
        >
          <template #cell(link)="{ item }">
            <b-icon-clipboard-plus
                class="user-enabled-email cursor-pointer mr-2"
                v-b-tooltip.hover="{
                  title: 'Kopírovat odkaz ke stažení',
                  delay: { show: 200, hide: 200 },
                  variant: 'secondary'
                }"
                @click="copyLink(item)"
            />
            <a :href="item.link" target="_blank">{{ item.link }}</a>
          </template>
          <template #cell(originalFileName)="{ item }">
            <div class="text-left">
              <b-icon-clipboard-plus
                  class="user-enabled-email cursor-pointer mr-2"
                  v-b-tooltip.hover="{
                    title: 'Kopírovat název souboru ke stažení + poznámku',
                    delay: { show: 200, hide: 200 },
                    variant: 'secondary'
                  }"
                  @click="copyFileNamePoznamka(item)"
              />
              {{ item.originalFileName }} <span class="color-grey">({{ fileSize(item.size) }})</span>
              <div v-if="item.comment" class="color-grey fs-12px">Poznámka: {{ item.comment }}</div>
            </div>
          </template>
          <template #cell(uploadedByUser)="{ item }">
            {{ item.uploadedByUser }}, {{ uploadedDate(item.uploadedDate) }}
          </template>
          <template #cell(deleteButton)="{ item }">
            <b-button
                @click="confirmDeleteFile(item)"
                variant="outline-danger"
            >
              Smazat
            </b-button>
          </template>
        </b-table>
      </template>

      <pridat-soubor-uschovna-form
        v-if="showPridatSouborModal"
        :show="showPridatSouborModal"
        :totalSize="totalSize"
        :allowedFileSize="allowedFileSize"
        @close-modal="showPridatSouborModal = false"
        @add-file="getMainData"
      />

    </b-overlay>

    <b-modal id="delete-file-confirmation-modal" centered hide-footer :title="getFileName" dialog-class="main-font mnogotochie">
      <p class="my-3 bold">Opravdu chcete tento soubor trvalé smazat?</p>
      <div class="d-flex justify-content-end pt-3">
        <b-button class="mr-3" variant="primary" @click="closeDeleteFileModal">Zrušit</b-button>
        <b-button variant="outline-danger" @click="deleteFile">Ano</b-button>
      </div>
    </b-modal>
  </div>

</template>

<script>
import PridatSouborUschovnaForm from "@/components/modals/PridatSouborUschovnaForm.vue";
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";

export default {
  name: "Uschovna",
  components: {PridatSouborUschovnaForm},
  mixins: [commonMixin],
  computed: {
    getFileName() {
      return this.deletingFile?.originalFileName || '';
    }
  },
  data() {
    return {
      isLoading: false,
      filesInfo: [],
      headers: [
        {label: 'Obhodní případ / zakázka', key: 'orderNumber', variant: 'color-1', sortable: true},
        {label: 'Název souboru ke stažení + poznámka', key: 'originalFileName', variant: 'color-1', class: 'max-w-500px', sortable: true},
        {label: 'Odkaz ke stažení', key: 'link', variant: 'color-1', sortable: true, class: 'mw-400px'},
        {label: 'Nahrál', key: 'uploadedByUser', variant: 'color-1', sortable: true},
        {label: 'Smazat po', key: 'expiredDate', variant: 'color-1', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: 'Smazat', key: 'deleteButton', variant: 'color-1'},
      ],
      showPridatSouborModal: false,
      deletingFile: null,
      totalSize: 0,
      allowedFileSize: 10 * 1000 * 1000 * 1000, // 10 GB
    }
  },
  methods: {
    openPridatSoubor() {
      this.showPridatSouborModal = true
    },
    async getInfo() {
      const response = await axios.get('/cloud/getFilesInfo', {params: {}});
      this.filesInfo = response.data?.data?.info || [];
    },
    uploadedDate(date) {
      return this.getFormattedDate(date);
    },
    confirmDeleteFile(item) {
      this.$bvModal.title = item.originalFileName;
      this.deletingFile = item;
      this.$bvModal.show('delete-file-confirmation-modal');
    },
    closeDeleteFileModal() {
      this.deletingFile = null;
      this.$bvModal.hide('delete-file-confirmation-modal');
    },
    copyLink(item) {
      this.copyToClipboard(item.link, 'Odkaz ke stažení byl zkopírován do schránky');
    },
    copyFileNamePoznamka(item) {
      const copyText = [`<b>${item.originalFileName}</b>`];
      copyText.push(`(${this.fileSize(item.size)})`);
      if (item.comment) {
        copyText.push(`<br>Poznámka: ${item.comment}`);
      }
      this.copyToClipboardHTML(copyText.join(' '), 'Název souboru ke stažení + poznámka byly zkopírovány do schránky.');
    },
    copyToClipboard(copyText, successMessage) {
      const el = document.createElement('textarea');
      el.value = copyText;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$snotify['success'](successMessage);
    },
    copyToClipboardHTML(copyText, successMessage) {
      const el = document.createElement('div');
      el.innerHTML = copyText;
      document.body.appendChild(el);
      const range = document.createRange();
      range.selectNodeContents(el);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      document.body.removeChild(el);
      this.$snotify['success'](successMessage);
    },
    async deleteFile() {
      try {
        if (!this.deletingFile) {
          return;
        }
        const response = await axios.get('/cloud/deleteFile', {params: { fileName: this.deletingFile.systemFileName }});
        if (response.data?.code === 200) {
          this.$snotify.success('Soubor byl smazán');
          await this.getMainData();
        } else {
          this.$snotify.error('Soubor se nepodařilo smazat');
        }
      } catch (e) {
        console.error(e);
        this.$snotify.error('Soubor se nepodařilo smazat');
      } finally {
        this.closeDeleteFileModal();
      }
    },
    async getFilesSize() {
      try {
        const response = await axios.get('/cloud/getFilesSize', {});
        this.totalSize = response.data?.data?.totalSize || 0;
      } catch (e) {
        console.error(e);
      }
    },
    async getMainData() {
      await this.getFilesSize();
      await this.getInfo();
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      await this.getMainData();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style scoped>

</style>